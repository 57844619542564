<template>
  <div class="layout">
    <header-bar></header-bar>
    <nav-bar></nav-bar>
    <!-- <div class="containerAll" v-if="hasLeft">
      <div class="containerBox">
        <sideBar />
        <div id="container_content">
          <router-view/>
        </div>
      </div>
    </div> -->
    <div id="container_content" >
      <router-view :key="key"/>
      
    </div>
    <footer-bar ></footer-bar>
    <!-- <div id="container" v-else>
      <router-view :key="key"/>
      <footer-bar ></footer-bar>
    </div> -->
    
  </div>
</template>

<script>
export default {
  name: 'layout',
  components: {
  },
  computed:{
    hasLeft(){
        let value=this.$route.meta.hasOwnProperty('hasLeft')
        let code=value?true:false
        return code
    },
    key() {
        //解决同一组件路由跳转，数据不刷新问题
        return this.$route.name !== undefined? this.$route.name +new Date(): this.$route +new Date()
    }
  }
};
</script>
<style type="text/css" lang="less">
	#container {
	  position: absolute;
    top: 180px;
    z-index: 9;
    width: 100%;
    
	}
 .containerAll{
  background: #e7f0f7;
  
  .containerBox{
    display: flex;
  }
 }
 #container_content{
  //margin-top:180px;
  //margin-left:250px;
  // padding:10px;
  // flex: 1;
  background: #e7f0f7;
margin-top: 132px;
min-height: 60vh;
  // position: absolute;
  //   top: 130px;
  //   z-index: 9;
  //   width: 100%;
  //   min-height: 79vh;
 }

 .layout{
  position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
 }
</style>
