
import Vue from 'vue'
import Router from 'vue-router'
import RouterConfig from './modules' 
import RouterCommon from './common'
import store from '@/store'
import axios from 'axios'
import { TOKEN_KEY } from '@/config/env.js'

Vue.use(Router)

const router=new Router({
    routes:RouterConfig.concat(RouterCommon)
})
router.beforeEach(async(to, from, next) => {
	// next()
	let token=localStorage.getItem(TOKEN_KEY)
	// if(to.name=='login'){
	// 	next()
	// }else{
	// 	if(token){
	// 		next()
	// 	} else {
	// 	   next({name:'login'}) 
	// 	   // next()
	// 	}
	// }
  next()
	
  
})
  

router.afterEach(to => {
})

export default router;