import Vue from 'vue'
import store from '@/store'
const installDirective = Vue => {
    Vue.directive('has',{
	    // update:function(el,bindings) {
	    //   Vue.nextTick(() =>{
	        
	    //   })      
	    // },
	    bind: function (el,bindings) {
            
        },
        inserted: function (el,bindings) {
            let id=bindings.value
	        if (id) {
	        	if(!store.state.allBtnList.includes(id) && el.parentNode){
	        		el.parentNode.removeChild(el)
	        	}
	        	// store.dispatch('getBtnRole',id).then((res)=>{
		        // 	if(!res && el.parentNode){
		        // 		el.parentNode.removeChild(el)
		        // 	}
		        // })
	          
	        }
            
        },
        componentUpdated:function (el,bindings) {
            let id=bindings.value
	        if (id) {
	        	if(!store.state.allBtnList.includes(id) && el.parentNode){
	        		el.parentNode.removeChild(el)
	        	}
	        	// store.dispatch('getBtnRole',id).then((res)=>{
		        // 	if(!res && el.parentNode){
		        // 		el.parentNode.removeChild(el)
		        // 	}
		        // })
	        }
            
        },
        unbind: function () {
            
        }
	})
}

export default installDirective;