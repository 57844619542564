
import Vue from 'vue';
const contexts = require.context('.', true, /\.vue$/)


console.log(contexts.keys()) // ["./home.js"] 返回一个数组


contexts.keys().forEach(component=>{
    let componentEntity = contexts(component).default;
    Vue.component(componentEntity.name, componentEntity);
})
