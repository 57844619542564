import Home from '@/views/layOut'

export default[
    {
        path: '/',
        redirect:'/home',
        name: 'home',
        component: Home,
        children:[
            {
                path:'/home',
                name:'home',
                component: () => import('@/views/home/index'),
                meta:{
                    title:'首页'
                }
            },
            {
                path:'/content',
                name:'content',
                component: () => import('@/views/home/content'),
                meta:{
                    title:'列表',
                    hasLeft:true
                }
            },
            {
                path:'/approved',
                name:'approved',
                component: () => import('@/views/home/approved'),
                meta:{
                    title:'Approved antivirals',
                    hasLeft:true
                }
            },
            {
                path:'/Investigative',
                name:'Investigative',
                component: () => import('@/views/home/Investigative'),
                meta:{
                    title:'Investigative antivirals',
                    hasLeft:true
                }
            },
            
            {
                path:'/antiviralTargets',
                name:'antiviralTargets',
                component: () => import('@/views/home/antiviralTargets'),
                meta:{
                    title:'Antiviral Targets',
                    hasLeft:true
                }
            },
            {
                path:'/protocols',
                name:'protocols',
                component: () => import('@/views/home/protocols'),
                meta:{
                    title:'Protocols',
                    hasLeft:true
                }
            },
            {
                path:'/guidelines',
                name:'guidelines',
                component: () => import('@/views/home/guidelines'),
                meta:{
                    title:'guidelines',
                    hasLeft:true
                }
            },
           
            
            {
                path:'/more',
                name:'more',
                component: () => import('@/views/home/more'),
                meta:{
                    title:'More',
                    hasLeft:true
                }
            },
            {
                path:'/medicineInfo',
                name:'medicineInfo',
                component: () => import('@/views/home/medicineInfo'),
                meta:{
                    title:'病毒详情',
                    hasLeft:true
                }
            },
            {
                path:'/teamIntroduction',
                name:'teamIntroduction',
                component: () => import('@/views/home/teamIntroduction'),
                meta:{
                    title:'teamIntroduction',
                    hasLeft:true
                }
            },
            {
                path:'/Publications',
                name:'Publications',
                component: () => import('@/views/home/publications'),
                meta:{
                    title:'publications',
                    hasLeft:true
                }
            },
            {
                path:'/databases',
                name:'databases',
                component: () => import('@/views/home/databases'),
                meta:{
                    title:'databases',
                    hasLeft:true
                }
            },
            

        ]
    }
]