import '@/assets/css/minireset.css';
import 'nprogress/nprogress.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import installDirective from './directives'
import components from './components'
import * as filters from './filters' // global filters
import '@/assets/css/common.less';
Vue.use(ElementUI)
//定义锚点跳转 
// import anchor from './util'
// Vue.directive('anchor', anchorFunc); 

//注册所有过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

//注册所有全局组件
Object.values(components).forEach(item=>{
  Vue.component(item.name,item);
})

//注册所有全局指令
installDirective(Vue);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
