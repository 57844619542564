/**
 * 配置编译环境和线上环境之间的切换
 * 
 * development  开发环境
 * production   生产环境
 * test         测试环境
 * 
 * baseUrl:     域名地址
 * imgBaseUrl:  图片所在域名地址
 */
let baseUrl = '';
let TOKEN_KEY = "inzone_token"
switch (process.env.VUE_APP_CURRENTMODE) {
    case 'development':
        baseUrl = 'https://bingduku.haoranvip.com/prod-api';
        // baseUrl = 'http://192.168.172.3:9911/dev-api';
        break;
    case 'production':
        baseUrl = 'https://bingduku.haoranvip.com/prod-api';
        break;
    case 'test':
        baseUrl = 'https://bingduku.haoranvip.com/prod-api';
        break;
    default:
        baseUrl = 'https://bingduku.haoranvip.com/prod-api';
        break;
}

export {
    baseUrl,
    TOKEN_KEY,
}
