import Axios from '@/plugins/http'

//根据标题查询

const medicinepage=(id)=>{
    return Axios.get(`/system/medicine?id=${id}`)
}
const reviewpage=(id,params)=>{
    return Axios.get(`/system/review?id=${id}`,{params})
}
const activitypage=(id,params)=>{
    return Axios.get(`/system/activity?id=${id}`,{params})
}
const effectspage=(id,params)=>{
    return Axios.get(`/system/effects?id=${id}`,{params})
}

const particularspage=(params)=>{
    return Axios.get(`/system/particulars`,{params})
}
const listpage=(params)=>{
    return Axios.post(`/system/list/page`,params)
}
const dictlist=(type,params)=>{
    return Axios.get(`/system/dict/data/type/${type}`,{params})
}
const listfixation=(params)=>{
    return Axios.get(`/system/list/fixation`,{params})
}
//根据标题查询全部
const medicineAll=(id,params)=>{
    return Axios.get(`/system/medicine/all?id=${id}`,{params})
}


// 药物分页
const reviewpageAll=(params)=>{
    return Axios.post(`/system/review/page`,params)
}
//抑制活性分页
const activitypageAll=(params)=>{
    return Axios.post(`/system/activity/page`,params)
}
//抑制活性分页
const effectspageAll=(params)=>{
    return Axios.post(`/system/effects/page`,params)
}


// table1
const indicatorspage=(id,params)=>{
    return Axios.get(`/system/indicators?id=${id}`,{params})
}

// 药物详情
const druDetails=(params)=>{
    return Axios.get(`/system/page`,{params})
}
// 旭日图
const mappage=(params)=>{
    return Axios.get(`/system/map`,{params})
}

//药物名称查询详情页
const getDrug=(params)=>{
    return Axios.get(`/system/page/getDrug`,{params})
}

//more
const teampage=(id,params)=>{
    return Axios.get(`/system/team?id=${id}`,{params})
}
// 出版物
const publicationspage=(id,params)=>{
    return Axios.get(`/system/publications?id=${id}`,{params})
}
const moviesPage=(params)=>{
    return Axios.get(`/system/movies`,{params})
}
const coursePage=(params)=>{
    return Axios.get(`/system/course`,{params})
}

//字典
//查询
const dictTypeList=(dictType,params)=>{
    return Axios.get(`/system/dict/sys/type/${dictType}`,{params})
}
//根据父id查询
const dictTypeParentId=(id,params)=>{
    return Axios.get(`/system/dict/sys?id=${id}`,{params})
}

//病毒列表
const medicinePageList=(params)=>{
    return Axios.post(`/system/medicine/pageList`,params)
}
//新闻
const newspageAllpage=(params)=>{
    return Axios.get(`/system/news/list`,{params})
}


//查询临床前研究列表
const studyAllpage=(params)=>{
    return Axios.get(`/system/study/list`,{params})
}

//资料库
const databasespageAllpage=(params)=>{
    return Axios.get(`/system/us/list`,{params})
}

//国际药物指南
const guidelinespageAllpage=(params)=>{
    return Axios.get(`/system/guidelines/list`,{params})
}

//国际药物指南
const protocolsPage=(params)=>{
    return Axios.get(`/system/details/list`,{params})
}

const newsDataDetail=(id,params)=>{
    return Axios.get(`/system/news/${id}`,params)
}
const medicineDataDetail=(id,params)=>{
    return Axios.get(`/system/medicine/${id}`,params)
}
const dictManageData=(dictType,params)=>{
    return Axios.get(`/system/dict/sys/type/${dictType}`,params)
}
//Clinical efficacy
const efficacyList=(params)=>{
    return Axios.get(`/system/efficacy/list`,{params})
}


//
//downloads
const downloadsPage=(params)=>{
    return Axios.get(`/system/downloads/list`,{params})
}

//Acknowledgements
const morePage=(params)=>{
    return Axios.get(`/system/more/list`,{params})
}

export {
    medicinepage,
    reviewpage,
    activitypage,
    effectspage,
    particularspage,
    listpage,
    dictlist,
    listfixation,
    medicineAll,
    reviewpageAll,
    activitypageAll,
    effectspageAll,
    indicatorspage,
    druDetails,
    mappage,
    getDrug,
    teampage,
    publicationspage,
    moviesPage,
    coursePage,
    dictTypeList,
    dictTypeParentId,
    medicinePageList,
    newspageAllpage,
    studyAllpage,
    databasespageAllpage,
    guidelinespageAllpage,
    protocolsPage,
    newsDataDetail,
    medicineDataDetail,
    dictManageData,
    efficacyList,
    downloadsPage,
    morePage

}