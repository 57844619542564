<template>
	<div class="footer" >
        <!-- <img src="@/assets/images/bottombg.jpg" ></img> -->
        <div class="containerBox footerBox">
            <div class="footer-list">
                <div class="footer-section">
                    <h3>Databases</h3>
                    <ul>
                        <li  @click="getTo(1)">How to cite our database</li>
                        <li  @click="getTo(2)">User Guide / FAQs</li>
                        <li  @click="getTo(3)">Database Download</li>
                    </ul>
                </div>
                <div class="footer-section">
                    <h3>Our Team</h3>
                    <ul>
                        <li @click="getTo(4)">Team introduction</li>
                        <li @click="getTo(5)">Publications</li>
                        <li >How to contact Us(liguangdi.research@gmail.com) </li>
                    </ul>
                </div>
                <div class="footer-section">
                    <h3>Other Resources</h3>
                    <ul>
                        <li @click="getTo(7)">US FDA Database</li>
                        <li @click="getTo(8)">Clinical Trial Database </li>
                        <li @click="getTo(9)">Virus Face</li>
                    </ul>
                </div>
            </div>
            
        </div>
        
    </div>
</template>
<script>
// import {  } from '@/api/login'

export default {
  name: "footerBar",
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {
    getTo(val){
        switch (val){
	        case 1:
                this.$router.push({ name: "databases" ,query:{type:'How to cite our database'}});
	            break;
            case 2:
                this.$router.push({ name: "databases" ,query:{type:'User Guide / FAQs'}});
	            break;
            case 3:
                this.$router.push({ name: "databases" ,query:{type:'Database Download'}});
	            break; 
            case 4:
                this.$router.push({ name: "teamIntroduction" });
	            break;
            case 5:
                this.$router.push({ name: "Publications" });
	            break;
            case 6:
	            // window.open('')
	            break;
	        case 7:
	            window.open('https://www.fda.gov/drugs/development-approval-process-drugs/drug-approvals-and-databases')
	            break;
            case 8:
	            window.open('https://clinicaltrials.gov/')
	            break;
            case 9:
	            window.open('http://www.virusface.com')
	            break;
	        default:
	            System.out.println( "default");
	            break;
	        }
    }
  },
  watch: {}
};
</script>
<style lang="less" >
.footer{
    // position: fixed;
    // bottom: 0;
    // width: 100%;
    // z-index: 99;
    //position: relative;
    height: 220px;
    background-image: url('@/assets/images/bottombg.jpg');
    .footer-list{
        display: flex;
        justify-content: space-between;
        padding: 28px 0;
        .footer-section{
            text-align: left;
            h3{
                font-size: 20px;
                font-weight: bold;
                line-height: 42px;
            }
            li{
                font-size: 16px;
                line-height: 40px;
                font-weight: 500;
                cursor: pointer;
                color: #333;
            }
        }
    }
}
</style>

