<template>
  

    <div class="sideBar">
      <div class="sideCenter">
        <el-menu
          :default-active="active"
          :default-openeds="openArr"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          unique-opened
        >
          <template v-for="(item,index) in menuList">
              <el-submenu
                :index="item.title"
                :key="index"
                v-if="item.children && item.children.length>0"
              >
                <template slot="title">
                  <i class="iconfont" :class="'icon-'+item.icon"></i>
                  <!-- <img :src="active && openArr1.length>0 && openArr1[0]==item.title?item.activeIcon:item.icon"/> -->
                  <span>{{item.title}}</span>
                </template>
                <template v-for="(ind, index2) in item.children">
                    <el-menu-item
                      :index="ind.component"
                      @click="goRouter(ind.component,ind.newOpen)"
                    >
                      <template slot="title">{{ind.title}}</template>
                    </el-menu-item>
                </template>
              </el-submenu>
              <el-menu-item
                :index="item.component"
                :key="index"
                @click="goRouter(item.component,item.newOpen)"
                class="oneMenu"
                style="padding-left: 12px !important; color: rgb(51, 51, 51);"
                v-else
              >
                <i class="iconfont" :class="'icon-'+item.icon" style="    margin-right: 10px;"></i>
                <!-- <img :src="item.icon" style="    margin-right: 10px; width: auto; height: auto;"/> -->
                <span>{{item.title}}</span>
              </el-menu-item>
          </template>
        </el-menu>
      </div>
    </div>
  
  
</template>
<script>
export default {
  name: "sideBar",
  data() {
    return {
      menuList: [
        {
          title: "RdRp inhibitors",
          name: "groupManage",
          component: "groupManage",
          icon: "huiyuan",
          children: [
            { title: "Remdesivir(Veklury)", component: "userManage" },
            { title: "Molnupiravir(Lagevrio)", component: "userManage" },
            { title: "JT001(VV116)", component: "userManage" }
          ]
        },
        {
          title: "RdRp inhibitors1",
          name: "rechargeManage",
          component: "rechargeManage",
          icon: "chongzhijilu",
          userType: ["SHOP_ADMIN"],
          children: [
            { title: "Remdesivir(Veklury)", component: "userManage" },
            { title: "Molnupiravir(Lagevrio)", component: "userManage" },
            { title: "JT001(VV116)", component: "userManage" }
          ]
        },
        {
          title: "RdRp inhibitors2",
          name: "statisticManage",
          component: "statisticManage",
          icon: "tongji",
          userType: ["SHOP_ADMIN"],
          children: [
            { title: "Remdesivir(Veklury)", component: "userManage" },
            { title: "Molnupiravir(Lagevrio)", component: "userManage" },
            { title: "JT001(VV116)", component: "userManage" },
            { title: "Remdesivir(Veklury)", component: "userManage" },
            { title: "Molnupiravir(Lagevrio)", component: "userManage" },
            { title: "JT001(VV116)", component: "userManage" }
          ]
        },
        
      ],
      active: "",
      openArr: [],
      openArr1:[],
      activeName:'',
    };
  },
  mounted() {
    this.changeRoute();
  },
  computed: {
    // menuList(){
    //   return this.$store.state.menuList
    // },
    userInfo(){
      return this.$store.state.userInfo
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      // this.openArr = keyPath
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // this.openArr = keyPath
      // console.log(key, keyPath);
    },
    goRouter(val,status) {
      if(status){
        let routeUrl = this.$router.resolve({
             name: val
        })
        window.open(routeUrl.href, '_blank');
      }else{
        this.$router.push({ name: val });
      }
      
    },
    changeRoute() {
      let vm = this;
      for (let i = 0; i < vm.list.length; i++) {
        let obj = vm.list[i];
        if (obj.children && obj.children.length > 0) {
          for (let j = 0; j < obj.children.length; j++) {
            let evt = obj.children[j];
            if (evt.component == vm.$route.name) {
              vm.active = evt.component;
              vm.openArr = [obj.title];
              vm.openArr1=[obj.title]
              vm.status = false;
              // console.log(vm.openArr)
              break;
            }
          }
          // if (!status) {
          //   break;
          // }
        } else {
          if (obj.component == vm.$route.name) {
            vm.active = obj.component;
            vm.openArr = [];
            vm.openArr1=[];
            break;
          }
        }
      }
    }
  },
  watch: {
    "$route.name": {
      handler: function(val, oldVal) {
        this.changeRoute();
      },
      immediate: true,
      deep: true
    }
    // list:{
    //   handler: function(val, oldVal) {
    //     this.changeRoute();
    //   },
    //   immediate: true,
    //   deep:true
    // }
  }
};
</script>
<style lang="less">
// .sideBar {
//   padding: 20px 0;
//   width: 250px;
//   text-align: left;
//   .el-menu{
//     background-color:#F1F6F9;
//     border-right:none!important;
//     overflow: hidden;

//     .el-submenu__title {
//       height: 50px;
//       line-height: 50px;
//         padding: 0 12px!important;
//         font-size: 15px;
//         >.iconfont{
//             margin-right: 10px;
//             font-size:22px;
//             color:#51a7ff;
//         }
//     }
//     .el-menu-item{
//       color: #999;
//       font-weight: 500;
//       padding: 0 0 0 55px!important;
//     }
//     .el-submenu.is-active .el-submenu__title{
//       background: -webkit-gradient(linear,left top,right top,from(#3a8ee6),to(#51a7ff));
//       background: linear-gradient(90deg,#3a8ee6 0,#51a7ff);
//       color: #fff;
//       border-radius: 4px;
//       >.iconfont{
//         color:#fff;
//       }
//     }
//     .el-menu-item.is-active{
//       color: #333;
//       position: relative;
//       font-weight: 600;
//     }
//     .oneMenu{
//       .iconfont{
//           margin-right: 10px;
//           font-size:22px;
//           color:#51a7ff;
//       }
//     }
//     .el-menu-item.is-active:not(.oneMenu)::before{
//       content: "";
//       position: absolute;
//       width: 16px;
//       height: 4px;
//       background: -webkit-gradient(linear,left top,right top,from(#3a8ee6),to(#51a7ff));
//       background: linear-gradient(90deg,#3a8ee6 0,#51a7ff);
//       top: 23px;
//       left: 30px;
//       border-radius: 2px;
//     }
//     >.el-submenu__title{
//       background: #3f62c8;
//     }
//   }
// }
</style>

