import axios from 'axios'
import NProgress from 'nprogress'
import {
    MessageBox,
    Message,
    Loading
} from 'element-ui'
// import {
//     getToken,
//     removeToken
// } from '@/utils/utils.js'
import {
    baseUrl
} from '../config/env'
const Axios = axios.create({
    baseURL: baseUrl
});



Axios.interceptors.request.use(
    config => {
        NProgress.start();
        // config.headers['authorization'] =getToken()   
        // config.headers['authorization']='1'
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

Axios.interceptors.response.use(
    response => {
        NProgress.done();
        let data = response.data;
        if (data.code > 100) {
            //Message({ message:data.msg,type: 'error'});
        }
        return data;
    },
    error => {
        NProgress.done();
        //  Message({ message:'服务器出错',type: 'error'});
        if (error.response) {
            switch (error.response.status) {
                case 303:
                    let message = '登录已过期，请重新登录'
                    Message.error(message)
                    // console.log(error.response.data.returnObject)
                    // removeToken()
                    break;
            }
        }
        return Promise.reject(error.response.data)
    }

);
Axios.all = axios.all;

export default Axios;