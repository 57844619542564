<template>
  <div class="nav">
    <!-- <div class="containerBox">
            <ul>
                <li >Approved antivirals</li>
                <li @click="getNav('Investigative')">Investigative antivirals</li>
                <li>Antiviral Targets</li>
                <li>Protocols</li>
                <li>More</li>
            </ul>
        </div> -->
    <div class="containerBox">
      <el-menu
      :default-active="active"
        active-text-color="#3f62c8"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item  index="0">Home</el-menu-item>
        <el-submenu index="1">
          <template slot="title">Approved antivirals</template>
          <el-menu-item
            :index="`1-${index}`"
            v-for="(item, index) in data1"
            :key="index"
            >{{ item.value }}</el-menu-item
          >
        </el-submenu>
        <el-menu-item  index="2">Investigative antivirals</el-menu-item>
        <!-- <el-menu-item  index="3">Antiviral Targets</el-menu-item> -->
        <el-submenu index="3">
          <template slot="title">Antiviral Targets</template>
          <el-menu-item
            :index="`3-${index}`"
            v-for="(item, index) in data3"
            :key="index"
            >{{ item.value }}</el-menu-item
          >
        </el-submenu>
        <!-- <el-menu-item  index="4">Protocols</el-menu-item> -->
        <el-submenu index="4">
          <template slot="title">Protocols</template>
          <el-menu-item
            :index="`4-${index}`"
            v-for="(item, index) in data1"
            :key="index"
            >{{ item.value }}</el-menu-item
          >
        </el-submenu>
        <el-submenu index="5">
          <template slot="title">Guidelines</template>
          <el-menu-item
            :index="`5-${index}`"
            v-for="(item, index) in data1"
            :key="index"
            >{{ item.value }}</el-menu-item
          >
        </el-submenu>
        <el-menu-item  index="6">More</el-menu-item>
        <!-- <el-submenu index="2">
          <template slot="title">Investigative antivirals</template>
          <el-menu-item
            :index="`2-${index}`"
            v-for="(item, index) in data2"
            :key="index"
            >{{ item.value }}</el-menu-item
          >
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">Antiviral Targets</template>
          <el-menu-item
            :index="`3-${index}`"
            v-for="(item, index) in data3"
            :key="index"
            >{{ item.value }}</el-menu-item
          >
        </el-submenu>
        <el-submenu index="4">
          <template slot="title">Protocols</template>
          <el-menu-item
            :index="`4-${index}`"
            v-for="(item, index) in data4"
            :key="index"
            >{{ item.value }}</el-menu-item
          >
        </el-submenu>
        <el-submenu index="5">
          <template slot="title">More</template>
          <el-menu-item
            :index="`5-${index}`"
            v-for="(item, index) in data5"
            :key="index"
            >{{ item.value }}</el-menu-item
          >
        </el-submenu> -->
      </el-menu>
    </div>
  </div>
</template>
<script>
import { medicinepage } from "@/api/medicine";

export default {
  name: "navBar",
  data() {
    return {
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      data5: [],
      selectRow:{},
      active: "",
    };
  },
  created() {
    this.getSelection();
  },
  computed: {},
  mounted(){
    
  },
  methods: {
    async getSelection() {
      let res1 = await medicinepage(102);
      this.data1 = res1.data;
      // let res2 = await medicinepage(103);
      // this.data2 = res2.data;
      let res3 = await medicinepage(104);
      this.data3 = res3.data;
      // let res4 = await medicinepage(105);
      // this.data4 = res4.data;
      // let res5 = await medicinepage(106);
      // this.data5 = res5.data;
    },
   

    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      let index=key.substring(2)
      console.log(index)
      if (keyPath[0] == "0") {
        this.$router.push({ name: "home" });
        
      } else if (keyPath[0] == "1") {
        this.selectRow={
          id:this.data1[index].id,
          name:this.data1[index].value
        }
        this.$router.push({
          path: "approved",
          query: { id: this.data1[index].id, name: this.data1[index].value ,fullpath: Date.parse(new Date()),introduce:this.data1[index].introduce,img:this.data1[index].img},
          
        });
      } else if (keyPath[0] == "2") {
        // this.$router.push({path:'content',query:{id:this.data2[key].id,name:this.data2[key].value}})
        this.$router.push({
          path: "Investigative",
          // query: { id: this.data2[index].id, name: this.data3[index].value },
        });
      } else if (keyPath[0] == 3) {
        this.$router.push({
          path: "antiviralTargets",
          query: { id: this.data3[index].id, },
        });
      }else if (keyPath[0] == 4) {
        this.$router.push({
          path: "protocols",
          query: { id: this.data1[index].id,  },
        });
      }else if (keyPath[0] == 5) {
       
        this.$router.push({
          path: "Guidelines",
          query: { id: this.data1[index].id,  },
        });
      }else if (keyPath[0] == 6) {
        this.$router.push({
          path: "more",
          // query: { id: this.data5[index].id, name: this.data5[index].value },
        });
      }
    },
    changeRoute() {
      let vm = this;
      for (let i = 0; i < vm.menuList.length; i++) {
        let obj = vm.menuList[i];
        if (obj.children && obj.children.length > 0) {
          for (let j = 0; j < obj.children.length; j++) {
            let evt = obj.children[j];
            if (evt.component == vm.$route.name) {
              vm.active = evt.component;
              vm.openArr = [obj.title];
              vm.openArr1=[obj.title]
              vm.status = false;
              // console.log(vm.openArr)
              break;
            }
          }
          // if (!status) {
          //   break;
          // }
        } else {
          if (obj.component == vm.$route.name) {
            vm.active = obj.component;
            vm.openArr = [];
            vm.openArr1=[];
            break;
          }
        }
      }
    }
    
  },
  watch: {
    "$route.name": {
      handler: function(val, oldVal) {
        this.changeRoute();
      },
      immediate: true,
      deep: true
    }
  },
};
</script>
<style lang="less" >
.nav {
  height: 56px;
  line-height: 56px;
  font-size: 20px;
  color: #000;
  font-weight: bold;
  position: fixed;
    width: 100%;
    top: 76px;
    background: #fff;
    z-index: 99;
  ul {
    display: flex;
    justify-content: space-between;
    li {
      // text-decoration:underline;
      cursor: pointer;
    }
  }
  .el-menu--horizontal > .el-menu-item,
  .el-menu--horizontal > .el-submenu .el-submenu__title {
    font-size: 20px;
    color: #000;
  }
  .el-menu--horizontal > .el-menu-item.is-active,
  .el-menu--horizontal > .el-submenu.is-active .el-submenu__title,
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu--horizontal > .el-submenu .el-submenu__title ,
  .el-menu--horizontal>.el-menu-item{
    // height: 64px;
    // line-height: 64px;
    height: 56px;
    line-height: 56px;
  }
}
</style>

