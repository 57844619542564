<template>
  <div class="header">
    <div class="containerBox">
      <div class="header-logo" @click="getHome()">
        <img src="@/assets/images/logo.png" alt="" />
        <div class="text">
          <p>Antiviral Database</p>
          <p>Find drug. Be sure.</p>
        </div>
      </div>
      <div class="header-search">
        <div class="input-action">
          <!-- <el-input
            placeholder="Search"
            v-model="searcInput"
            @keyup.enter.native="search()"
            size="mini"
          ></el-input>
          <el-button slot="append" icon="el-icon-search" @click="search">
          </el-button> -->
          <el-input placeholder="Search" size="mini" v-model="searcInput" class="input-with-select"  @keyup.enter.native="search()">
            <el-button slot="append" icon="el-icon-search"  @click="search()"></el-button>
          </el-input>
        </div>
        <div class="input-menu">
          <span>Example:Remdesivir</span>
          <!-- <span>Advanced search</span> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import {  } from '@/api/login'

export default {
  name: "headerBar",
  data() {
    return {
      searcInput: "",
    };
  },
  created() {},
  computed: {},
  methods: {
    getHome() {
      this.$router.push({ name: "home" });
    },
    search() {
      this.$router.push({
        path: "content",
        query: { drugName: this.searcInput },
      });
    },
  },
  watch: {},
};
</script>
<style lang="less" scoped>
.header {
  height: 76px;
  background: #1a3376;
  //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  text-align: left;
  color: #fff;
  .containerBox {
    display: flex;
    .header-logo {
      // width: 236px;
      width: 836px;
      display: flex;
      img {
        width: 66px;
      }
      .text{
        flex: 1;
        padding-top: 16px;
    padding-left: 5px;
        p:first-child{
          font-size: 24px;
          font-weight: bold;
          line-height: 1;
        }
        p:last-child{
          font-size: 18px;
          // font-weight: bold;
          color: #fede00;
          line-height: 1;
        }
      }
    }
    .header-search {
      margin-left: 65px;
      padding: 14px 0;
      .input-action {
        width: 850px;
        display: flex;
        .el-button {
          // width: 50px;
          // padding: 0;
          // background: #3f62c8;
          // color: #fff;
          // border: none;
          // border-radius: 0;
          // height: 32px;
        }
        .el-input {
          flex: 1;

          .el-input__inner {
          }
        }
      }

      .input-menu {
        font-size: 14px;
        margin-top: 8px;
        span {
          margin-right: 45px;
        }
      }
    }
  }
}
</style>
<style>
.header-search .el-input-group__append {
  background-color:#3f62c8;
}
.header-search .el-icon-search{
  color: #fff;
}
/* 
.header-search .el-input__inner {
  height: 32px !important;
  border-radius: 0;
} */
</style>

